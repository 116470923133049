<template>
  <div>
    <Header/>
    <router-view></router-view>
    <LeftNav />
    <Footer/>
  </div>
</template>

<script>
import LeftNav from '../components/LeftNav.vue';
export default {
  components: {
    Header: () => import("@/components/Header.vue"),
    Footer: () => import("@/components/Footer.vue"),
    Iframe: () => import("@/components/Iframe.vue"),
    LeftNav:()=>import("@/components/LeftNav.vue")
  },
  methods: {}
};
</script>

<style>
.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}
</style>

