<template>

  <!--
  <div class="left-nav">
    <el-tooltip class="item" effect="dark" content="电话号码" placement="left">
      <div class="left-nav-item">
        <img src="" width="40" height="30">
        <p>电话咨询</p>
      </div>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" placement="left">
      <div slot="content">
        <img src="" alt="二维码图片" height="20" width="20">
      </div>
      <div class="left-nav-item bg1">
        <img src="" width="40" height="30">
        <p>电话咨询</p>
      </div>
    </el-tooltip>
      <div class="left-nav-item bg2">
        <img src="" width="40" height="30">
        <p>电话咨询</p>
      </div>

  </div>
  -->
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.left-nav{
  width: 65px;
  position: fixed;
  right: 10px;
  bottom: 200px;
  z-index:1000;
  &-item{
    background-color: #5c8de6;
    width: 70px;
    height: 80px;
    padding: 10px 5px;
    box-sizing: border-box;
    margin-bottom: 3px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    p{
      line-height: 36px;
      font-size: 12px;
    }
    &.bg1{
      background-color:#4fc0ff;

    }
    &.bg2{
      background-color: #eaa800;
    }
  }
}
</style>